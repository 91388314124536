<template>
  <div>
    <startup-card />
    <result-card
      v-if="technologyReadinessLevelSaveStatus.status !== null"
      :result="technologyReadinessLevelSaveStatus"
    />
    <validation-observer
      v-else
      ref="simpleRules"
    >
      <template v-if="technologyReadinessLevel.length">
        <b-card
          v-for="trl in technologyReadinessLevel"
          :key="trl.id"
        >
          <b-card-title class="mb-1">
            {{ trl.title }}
          </b-card-title>
          <b-card-sub-title>{{ trl.subtitle }}</b-card-sub-title>
          <p class="font-small-3 text-primary font-italic">
            {{ trl.content }}
          </p>
          <div
            v-for="question in trl.questions"
            :key="question.id"
          >
            <question :question="question" />
          </div>
        </b-card>
        <div>
          <b-form-checkbox
            v-model="draftStatus"
            class="mb-1"
            switch
          >
            {{ $t('Taslak Olarak Kaydet') }}
          </b-form-checkbox>
          <draft-save-alert v-if="draftStatus" />
        </div>
        <template v-if="draftStatus">
          <b-button
            variant="warning"
            @click="saveDraft"
          >
            {{ $t('Taslak Olarak Kaydet') }}
          </b-button>
        </template>
        <template v-else>
          <b-button
            variant="primary"
            @click="saveData"
          >
            {{ $t('Gönder') }}
          </b-button>
        </template>
      </template>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import StartupCard from '@/views/App/Interests/Requests/components/StartupCard.vue'
import ResultCard from '@/views/App/NewStartups/ResultCard.vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import Question from '@/views/App/NewStartups/TechnologyReadinessLevel/Question.vue'
import DraftSaveAlert from '@/views/App/NewStartups/components/DraftSaveAlert.vue'

export default {
  name: 'TechnologyReadinessLevel',
  components: {
    BFormCheckbox,
    ValidationObserver,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BButton,
    StartupCard,
    ResultCard,
    Question,
    DraftSaveAlert,
  },
  data() {
    return {
      required,
      draftStatus: false,
    }
  },
  computed: {
    technologyReadinessLevel() {
      return this.$store.getters['technologyReadinessLevel/getTechnologyReadinessLevel']
    },
    technologyReadinessLevelSaveStatus() {
      return this.$store.getters['technologyReadinessLevel/getTechnologyReadinessLevelSaveStatus']
    },
    trlResult() {
      return this.$store.getters['technologyReadinessLevel/getTrlResult']
    },
  },
  created() {
    localize(this.$i18n.locale)
    this.getStartup()
    this.getTechnologyReadinessLevel()
  },
  methods: {
    getStartup() {
      this.$store.dispatch('memberStartups/memberStartupView', this.$route.params.id)
    },
    setTrlResult(data) {
      const result = []
      data.forEach(e => {
        e.questions.forEach(item => {
          result.push({
            id_trl_questions: item.id,
            id_trl_options: item.result,
            id_startups: this.$route.params.id,
          })
        })
      })
      this.$store.dispatch('technologyReadinessLevel/setTrlResult', result)
    },
    getTechnologyReadinessLevel() {
      this.$store.dispatch('technologyReadinessLevel/technologyReadinessLevel', {
        id_startups: this.$route.params.id,
      })
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.setTrlResult(this.technologyReadinessLevel)
          if (this.trlResult) {
            this.$store.dispatch('technologyReadinessLevel/saveData', this.trlResult)
          }
        }
      })
    },
    saveDraft() {
      this.setTrlResult(this.technologyReadinessLevel)
      if (this.trlResult) {
        this.$store.dispatch('technologyReadinessLevel/saveDraft', this.trlResult)
      }
    },
  },
}
</script>

<style scoped></style>
