<template>
  <div>
    <validation-provider
      #default="{ errors }"
      :name="question.title"
      rules="required"
    >
      <b-form-group
        :label="question.title"
        :label-for="'question_' + question.id"
      >
        <v-select
          :id="'question_' + question.id"
          v-model="question.result"
          label="title"
          :options="question.options"
          :reduce="question => question.id"
          :placeholder="$t('Seçiniz')"
        />
      </b-form-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </div>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Question',
  components: {
    ValidationProvider,
    BFormGroup,
    vSelect,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
